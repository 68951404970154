import { BaseType } from 'antd/es/typography/Base';

const LOCAL_STORAGE = {
  ACCESS_TOKEN: 'ONECMS_AccessToken',
  REFRESH_TOKEN: 'ONECMS_RefreshToken',
  ABILITIES: 'ONECMS_Abilities',
  LANGUAGE: 'ONECMS_Language',
  PREVIEW_DATA: 'ONECMS_PreviewData',
  FINDER_TREE_SELECTED: 'ONECMS_TreeSelected',
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const COLORS = {
  COOL_BLACK: '#002b60',
};

export const DEFAULT_QUERY_OPTION = {
  retry: 0,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const URL_CONST = {
  MEDIA_FILE_UPLOAD_CHUNK: 'api/v1/media-files/upload-chunk',
  MEDIA_FILE_MERGE_CHUNK: 'api/v1/media-files/merge-chunk',
};

export const TARGET_LIST_OPTIONS = [
  {
    label: 'Mở liên kết trong tab hiện tại',
    value: 1,
  },
  {
    label: 'Mở liên kết trong tab mới',
    value: 2,
  },
];
export const TARGET_LIST_LABEL = [
  {
    label: '_self',
    value: 1,
  },
  {
    label: '_blank',
    value: 2,
  },
];

export const socialList: OptionType[] = [
  {
    label: 'Facebook',
    value: 1,
  },
  {
    label: 'Twitter',
    value: 2,
  },
];

export const newsListSortBy: OptionType[] = [
  {
    label: 'Thứ tự hiển thị',
    value: 'displayOrder',
  },
  {
    label: 'Ngày đăng',
    value: 'displayOrder',
  },
];

export const newsListSortType: OptionType[] = [
  {
    label: 'Ascending',
    value: 'asc',
  },
  {
    label: 'Descending',
    value: 'desc',
  },
];

export default LOCAL_STORAGE;

export const INTEGER_REGEX = /^[+-]?((\d*\.?\d+(?:[Ee][+-]?\d+)?)|(\d+\.))$/;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^0[1-9][0-9]{8}$/;

export const ROUTE_PATHS = {
  /* BLOCK TEMPLATE */
  PAGE_TEMPLATE_MANAGEMENT: '/page-templates',
  /* PAGE */
  PAGE_MANAGEMENT: '/page-management',
  PAGE_DETAIL: '/page',
  /* FILE */
  FILE_MANAGEMENT: '/files-management',
  /* MENU */
  MENU_MANAGEMENT: '/menu-management',
  MENU_DETAIL: '/menu',
  /* NEWS */
  NEWS_MANAGEMENT: '/news-management',
  NEWS_DETAIL: '/news',
  NEWS_CATEGORY_MANAGEMENT: '/news-category-management',
  NEWS_CATEGORY_DETAIL: '/news-category',
  /* BANNER */
  BANNER_MANAGEMENT: '/banner-management',
  BANNER_DETAIL: '/banner',
  /* TRANSLATIONS */
  FE_TRANSLATION_MANAGEMENT: '/fe-translation',
  CMS_TRANSLATION_MANAGEMENT: '/cms-translation',
  /* REDIRECT */
  REDIRECT_MANAGEMENT: '/redirect',
  REDIRECT_IMPORT: '/redirect-import',
  /* ACTIVITY */
  ACTIVITY_MANAGEMENT: '/activity-logs',
  /* ROLES */
  ROLES_MANAGEMENT: '/roles',
  ROLES_DETAIL: '/role-detail',
  /* USERS */
  USERS_MANAGEMENT: '/users-management',
  USERS_DETAIL: '/user',
  /* FAQ */
  FAQ_MANAGEMENT: '/faq-management',
  FAQ_DETAIL: '/faq',
  FAQ_CATEGORY_MANAGEMENT: '/faq-category-management',
  FAQ_CATEGORY_DETAIL: '/faq-category',
  /* CONFIG */
  SYSTEM_MANAGEMENT: '/system',
  SYSTEM_CONFIG_MANAGEMENT: '/config-management',
  ERRORS_MANAGEMENT: '/errors-management',
  /* AUTH */
  LOGIN: '/login',
  /* CONTACT */
  CONTACT_MANAGEMENT: '/contact-management',
  CONTACT_PROBLEM_MANAGEMENT: '/contact-problem-management',
  CONTACT_PROBLEM_DETAIL: '/contact-problem-detail',
  /* EMAIL TEMPLATE */
  EMAIL_TEMPLATE_MANAGEMENT: '/email-template-management',
  EMAIL_TEMPLATE_DETAIL: '/email-template-detail',
  /* FORM */
  FORM_MANAGEMENT: '/form-management',
  FORM_DETAIL: '/form-detail',
  SUBMIT_FORM_MANAGEMENT: '/submit-form-management',
  SUBMIT_FORM_LIST_BY_FORM: '/submit-form-list-by-form',
  /* STATIC BLOCK */
  STATIC_BLOCK_MANAGEMENT: '/static-block-management',
  STATIC_BLOCK_DETAIL: '/static-block-detail',
  /* CONFIG */
  CONFIG_TOPUP: '/config-topup',
  CONFIG_FIRST_PRIZE: '/config-first-prize',
  CONFIG_SPECIAL_PRIZE: '/config-special-prize',
  /* STORE */
  STORE_MANAGEMENT: '/store-management',
  STORE_DETAIL: '/store-detail',
  /* CUSTOMER LUCKY CODE */
  CUSTOMER_LUCKY_CODE_MANAGEMENT: '/customer-lucky-code-management',
  CUSTOMER_LUCKY_CODE_DETAIL: '/customer-lucky-code-detail',

  /* Topup Attempts */
  TOPUP_ATTEMPT_MANAGEMENT: '/topup-attempt',

  /* Topup request */
  TOPUP_REQUEST_MANAGEMENT: '/topup-request',

  /* Topup ransactions */
  TOPUP_TRANSACTION_MANAGEMENT: '/topup-transaction',

  /* CustomerPrize */
  CUSTOMER_FIRST_PRIZE_MANAGEMENT: '/customer-first-prize',
  CUSTOMER_FIRST_PRIZE_DETAIL: '/customer-first-prize-detail',
  CUSTOMER_SPECIAL_PRIZE_DETAIL: '/customer-special-prize-detail',
  CUSTOMER_SPECIAL_PRIZE_MANAGEMENT: '/customer-special-prize',

  /* Customer lucky draw */
  CUSTOMER_LUCKY_DRAW_MANAGEMENT: '/customer-lucky-draw',
  CUSTOMER_LUCKY_DRAW_DETAIL: '/customer-lucky-detail',

  /* Topup Buy Cards */
  TOPUP_BUY_CARDS_MANAGEMENT: '/topup-buy-cards',

  /* Quotas Management */
  QUOTAS_MANAGEMENT: '/quotas-management',
  QUOTAS_HISTORY_MANAGEMENT: '/quotas-history-management',

  SMSGATEWAY: '/sms-gateway',
};

export const videoTypes: OptionType[] = [
  {
    label: 'Upload',
    value: 'upload',
  },
  {
    label: 'Youtube',
    value: 'youtube',
  },
  {
    label: 'Vimeo',
    value: 'vimeo',
  },
];

export const defaultSessionsCode = {
  hanoiCode: '1028580',
  sontayCode: '9074107',
  saigonCode: '1028581',
  danangCode: '1028809',
};

export const regionTypes: OptionType[] = [
  {
    label: 'Northern Vietnam',
    value: 'Northern Vietnam',
  },
  {
    label: 'Central Vietnam',
    value: 'Central Vietnam',
  },
  {
    label: 'Southern Vietnam',
    value: 'Southern Vietnam',
  },
];

export const probabilitiesTypes: OptionType[] = [
  {
    label: 'Percentage',
    value: 'percent',
  },
  {
    label: 'Fixed',
    value: 'fixed',
  },
];

export const amountTopupTypes: OptionType[] = [
  {
    label: '10.000 VND',
    value: 10,
  },
  {
    label: '20.000 VND',
    value: 20,
  },
  {
    label: '30.000 VND',
    value: 30,
  },
  {
    label: '50.000 VND',
    value: 50,
  },
];

export const phaseTypes: OptionType[] = [
  {
    label: 'Đợt 1',
    value: 1,
  },
  {
    label: 'Đợt 2',
    value: 2,
  },
  {
    label: 'Đợt 3',
    value: 3,
  },
  {
    label: 'Đợt 4',
    value: 4,
  },
];

export const firstPhaseTypes: OptionType[] = [
  {
    label: 'Đợt 1',
    value: 1,
  },
  {
    label: 'Đợt 2',
    value: 2,
  },
  {
    label: 'Đợt 3',
    value: 3,
  },
  {
    label: 'Đợt 4',
    value: 4,
  },
  {
    label: 'Đợt 5',
    value: 5,
  },
  {
    label: 'Đợt 6',
    value: 6,
  },
  {
    label: 'Đợt 7',
    value: 7,
  },
  {
    label: 'Đợt 8',
    value: 8,
  },
];

export const topupPhaseTypes: OptionType[] = [
  {
    label: 'Phase 1',
    value: 1,
  },
  {
    label: 'Phase 2',
    value: 2,
  },
  {
    label: 'Phase 3',
    value: 3,
  },
];

export const customerLuckyCodePhases: OptionType[] = [
  {
    label: 'system.all',
    value: 0,
  },
  {
    label: 'customerLuckyCode.phase1',
    value: 1,
  },
  {
    label: 'customerLuckyCode.phase2',
    value: 2,
  },
  {
    label: 'customerLuckyCode.phase3',
    value: 3,
  },
];

export const customerLuckyCodeStage: OptionType[] = [
  {
    label: 'system.phase1',
    value: 1,
  },
  {
    label: 'system.phase2',
    value: 2,
  },
  {
    label: 'system.phase3',
    value: 3,
  },
];

export const customerLuckyCodeSpecialPrizeStage: OptionType[] = [
  {
    label: 'system.stage1',
    value: 1,
  },
];

export const customerLuckyCodeFirstPrizeStage: OptionType[] = [
  {
    label: 'system.stage1',
    value: 1,
  },
  {
    label: 'system.stage2',
    value: 2,
  },
  {
    label: 'system.stage3',
    value: 3,
  },
  {
    label: 'system.stage4',
    value: 4,
  },
];

export const stagePrizeDummy = [
  {
    label: 'system.stage1',
    value: 1,
  },
  {
    label: 'system.stage2',
    value: 2,
  },
  {
    label: 'system.stage3',
    value: 3,
  },
  {
    label: 'system.stage4',
    value: 4,
  },
  {
    label: 'system.stage5',
    value: 5,
  },
  {
    label: 'system.stage6',
    value: 6,
  },
];

export const stageSpecialPrizeDummy = [
  {
    label: 'system.stage1',
    value: 1,
  },
  {
    label: 'system.stage2',
    value: 2,
  }
];

export const phasePrizeDummy = [
  {
    label: 'system.phase1',
    value: 1,
  },
  {
    label: 'system.phase2',
    value: 2,
  },
  {
    label: 'system.phase3',
    value: 3,
  },
];

export const telcos = {
  1: 'Viettel',
  2: 'Vinaphone',
  3: 'Mobifone',
  4: 'Vietnamobile',
};

export const sources = {
  1: 'MicroSite',
  2: 'ZaloOA',
  3: 'SMS',
};

export const topUpExecuteOptions: OptionType[] = [
  {
    label: 'Chưa nhận được tiền',
    value: 1,
  },
];

export const telcoOptions = [
  {
    label: telcos[1],
    value: 1,
  },
  {
    label: telcos[2],
    value: 2,
  },
  {
    label: telcos[3],
    value: 3,
  },
  {
    label: telcos[4],
    value: 4,
  },
];

export const sourceOptions = [
  {
    label: 'MicroSite',
    value: 1,
  },
  {
    label: 'ZaloOA',
    value: 2,
  },
  {
    label: 'SMS',
    value: 3,
  },
];

export const statusOptions = [
  {
    label: 'system.pending',
    value: 2,
  },
  {
    label: 'system.exchanged',
    value: 1,
  },
  {
    label: 'system.canceled',
    value: 3,
  },
];

export const prizeTypeOptions = [
  {
    label: 'customerPrize.backup',
    value: '1',
  },
  {
    label: 'customerPrize.notBackup',
    value: '0',
  },
];

export const prizeOptions = [
  {
    label: 'customerLuckyCode.topup',
    value: 'top-up',
  },
  {
    label: 'customerLuckyCode.1st',
    value: '1st',
  },
  {
    label: 'customerLuckyCode.special',
    value: 'special',
  },
];

export const timeGoneTooltips = [
  {
    label: 'Time gone',
    note: 'Số ngày đã chạy chương trình / Tổng số ngày chạy chương trình.',
  },
];

export const legendTooltips = [
  'prizeExchange.tooltipNew',
  'prizeExchange.tooltipPending',
  'prizeExchange.tooltipExchanging',
  'prizeExchange.tooltipExchanged',
  'prizeExchange.tooltipCanceled',
];

export const userTopUpTooltips = [
  {
    label: '1. Tổng số người dùng nhận giải Topup',
    note: 'Tổng số lượng người dùng đã nhận Topup ( Unique số điện thoại ).',
  },
  {
    label: '2. Trung bình số lượng giải Topup / Người dùng',
    note: 'Tổng số lượng giải Topup đã thành công / Tổng số lượng người dùng đã nhận giải Topup.',
  },
];

export const usedLimitTopUpTooltips = [
  {
    label: '1. Tổng hạn mức',
    note: 'Tổng số lượng giải Topup * giá trị thẻ cào.',
  },
  {
    label: '2. Hạn mức đã sử dụng',
    note: 'Tổng số lượng giải Topup đã thành công * giá trị thẻ cào.',
  },
  {
    label: '3. Hạn mức còn lại',
    note: '(Tổng số lượng giải Topup - Tổng số lượng giải Topup thành công) * giá trị thẻ cào.',
  },
];

export const textStatusSMS = (status?: number) => {
  switch (status) {
    case 1:
      return 'customerPrize.fail';
    case 2:
      return 'customerPrize.processing';
    case 3:
      return 'customerPrize.success';
    default:
      return '';
  }
};

export const colorStatusSMS = (status?: number): BaseType => {
  switch (status) {
    case 1:
      return 'danger';
    case 2:
      return 'warning';
    case 3:
      return 'success';
    default:
      return 'success';
  }
};
