import axiosInstance from '../common/instance';

import {
  TopActivityLogsTypes,
  GaSummariesParamsTypes,
  GaSummariesTypes,
  GaPagesTrackingTypes,
  GaUsersTracking,
  QuickActionSummaryType,
  QuickActionSummaryParams,
  GaPageTrackingTypes,
  GaPageTrackingParamsTypes,
  GaLocationsTrackingTypes,
  DateParams,
  DeviceList,
  GaVisitorParamsTypes,
  GaVisitorTracking,
  GetPrizeCountDateParams,
  PrizeCountTypes,
  CustomerParticipatedTypes,
  TopupPrizeCountTypes,
  TopupLimitReportTypes,
  TopupUserReportTypes,
  TopupSuccessReportTypes,
  GeneralDataTypes,
  TopupSummariesTypes,
  SMSBrandNameTypes
} from './types';

const prefixPath = 'dashboard/';

export const getTopActivityLogsService = async (): Promise<
  TopActivityLogsTypes[]
> => {
  const res = await axiosInstance.get(`${prefixPath}top-activity-logs`);
  return res.data.data;
};
/**
 * ga Key
 * identifier, users, sessions, avgSessionDuration, pageviewsPerSession,
 * newUsers, bounceRate, adMatchedQuery
 */

const postGaSummariesService = async (
  params: GaSummariesParamsTypes
): Promise<GaSummariesTypes[]> => {
  /**
   * metrics: sessions, activeUsers
   * groupBy: date, yearMonth
   */
  const res = await axiosInstance.post(`${prefixPath}ga-summaries`, params);
  return res.data.data;
};

export const postGaPagesTrackingService = async (
  params: GaSummariesParamsTypes
): Promise<GaPagesTrackingTypes[]> => {
  /**
   * Metric: newUsers, engagedSessions, engagementRate,
   * bounceRate, itemViews, screenPageViewsPerSession, screenPageViews
   */
  const res = await axiosInstance.post(
    `${prefixPath}ga-pages-tracking`,
    params
  );
  return res.data.data;
};

export const postGaUsersTrackingService = async (
  params: GaSummariesParamsTypes
): Promise<GaUsersTracking[]> => {
  // region, country, deviceCategory, browser
  const res = await axiosInstance.post(
    `${prefixPath}ga-users-tracking`,
    params
  );
  return res.data.data;
};
export const getGaVisitorTrackingService = async (
  params: GaVisitorParamsTypes
): Promise<GaVisitorTracking[]> => {
  const res = await axiosInstance.get(`${prefixPath}ga-visitors-tracking`, { params });
  return res.data.data;
};

export const quickActionSummariesService = async (
  params: QuickActionSummaryParams
): Promise<QuickActionSummaryType[]> => {
  const res = await axiosInstance.get(
    `${prefixPath}${params.prefix}-summaries`
  );
  return res.data.data;
};

export const getGAPageTrackingList = async (
  params: GaPageTrackingParamsTypes
): Promise<GaPageTrackingTypes[]> => {
  const res = await axiosInstance.get(`${prefixPath}ga-pages-tracking`, { params });
  return res.data.data;
};

export const getGaLocationTracking = async (
  params: DateParams
): Promise<GaLocationsTrackingTypes[]> => {
  // region, country, deviceCategory, browser
  const res = await axiosInstance.get(`${prefixPath}ga-locations-tracking`, { params });
  return res.data.data;
};

export const getDeviceTrackingService = async (
  params: DateParams,
): Promise<DeviceList[]> => {
  const res = await axiosInstance.get(`${prefixPath}ga-devices-tracking`, { params });
  return res.data.data;
};

export const getFirstPrizeCountService = async (
  params: GetPrizeCountDateParams,
): Promise<PrizeCountTypes> => {
  const res = await axiosInstance.get('dashboard/first-prize-count', { params });
  return res.data.data;
};

export const getSpecialPrizeCountService = async (
  params: GetPrizeCountDateParams,
): Promise<PrizeCountTypes> => {
  const res = await axiosInstance.get('dashboard/special-prize-count', { params });
  return res.data.data;
};

export const getCustomerParticipatedService = async (params: {
  phase?: number
}): Promise<CustomerParticipatedTypes> => {
  const res = await axiosInstance.get('dashboard/customer-participated', { params });
  return res.data.data;
};

export const getTopupPrizeCountService = async (params: { phase?: number })
  : Promise<TopupPrizeCountTypes> => {
  const res = await axiosInstance.get('dashboard/topup-prize-count', { params });
  return res.data.data;
};

export const getTopupUserReportService = async (params: { phase?: number })
  : Promise<TopupUserReportTypes> => {
  const res = await axiosInstance.get('dashboard/topup-user-report', { params });
  return res.data.data;
};

export const getTopupLimitReportService = async (params: { phase?: number })
  : Promise<TopupLimitReportTypes> => {
  const res = await axiosInstance.get('dashboard/topup-limit-report', { params });
  return res.data.data;
};

export const getTopupSuccessReportService = async (params: { yearMonth?: string, phase?: number })
  : Promise<TopupSuccessReportTypes> => {
  const res = await axiosInstance.get('dashboard/topup-success-report', { params });
  return res.data.data;
};

export const getGeneralDataService = async (params: { phase?: number })
  : Promise<GeneralDataTypes> => {
  const res = await axiosInstance.get('dashboard/general', { params });
  return res.data.data;
};

export const getTopupSummariesService = async (params: { phase?: number })
  : Promise<TopupSummariesTypes> => {
  const res = await axiosInstance.get('dashboard/topup-summaries', { params });
  return res.data.data;
};

export const getSMSBrandNameService = async (params: { phase?: number })
  : Promise<SMSBrandNameTypes> => {
  const res = await axiosInstance.get('dashboard/sms-brand-name', { params });
  return res.data.data;
};

export default postGaSummariesService;
