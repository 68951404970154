import { ShoppingOutlined } from '@ant-design/icons';
import {
  Button,
  Select,
  Space,
  Tooltip,
  Typography,
  message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { StatusBuyCardLabel } from 'common/components/StatusLabel';
import useDidMount from 'common/hooks/useDidMount';
import {
  exportTopupBuyCardsAccountService,
  exportTopupBuyCardsService,
  getTopupBuyCardsService
} from 'common/services/topupBuyCards';
import { Telco, TopupBuyCardData } from 'common/services/topupBuyCards/types';
import { buyCardTopUpRequest } from 'common/services/topupRequest';
import { BuyCardStatusEnum } from 'common/services/topupRequest/types';
import { customerLuckyCodePhases } from 'common/utils/constant';
import {
  detectError, detectSortType, formatDateTime, formatNumberWithSeparators, formatPhoneVietnamese
} from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';

const TopupBuyCard: React.FC<ActiveRoles> = ({ roleOther }) => {
  /* Hooks */
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const {
    defaultPageSize, advancedFilter
  } = useAppSelector((state) => state.system);

  /* State */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const [selectedPhase, setSelectedPhase] = useState<number | undefined>(undefined);
  const [sortType, setSortType] = useState<{ field: string, type: SortType | undefined }>();
  /* Variables */
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('topupBuyCard', advancedFilter),
    [advancedFilter]
  );

  /* Queries */
  const queryKey = ['getTopupBuyCardsMutate', keyword, currentPage, currentView, selectedFilterList, selectedPhase, sortType];
  const { data: topupBuyCardRes, isLoading } = useQuery(
    queryKey,
    () => getTopupBuyCardsService({
      page: currentPage,
      limit: currentView,
      phase: selectedPhase,
      sortBy: sortType?.field,
      sortType: sortType?.type,
      ...sortType?.type && { sortType: sortType.type },
      ...returnParamsFilter,
    }),
    {
      enabled: !!currentPage,
    }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['exportTopupBuyCardsMutate'],
    () => exportTopupBuyCardsService({
      keyword,
      page: currentPage,
      limit: currentView,
      phase: selectedPhase,
      ...returnParamsFilter,
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.uploadError'));
      }
    }
  );

  const { mutate: exportAccountMutate, isLoading: exportAccountLoading } = useMutation(
    ['exportTopupBuyCardsAccountMutate'],
    () => exportTopupBuyCardsAccountService({
      keyword,
      page: currentPage,
      limit: currentView,
      phase: selectedPhase,
      ...returnParamsFilter,
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.uploadError'));
      }
    }
  );

  const { mutate: buyCardMutate, isLoading: buyCardLoading } = useMutation(
    ['topup-buyCard-mutate'],
    (id: number) => buyCardTopUpRequest(id),
    {
      onSuccess() {
        message.success(t('message.buyCardSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(t(detectError(Number(err.response?.status))));
        }
      }
    }
  );

  const tableData: TopupBuyCardData[] = useMemo(() => (topupBuyCardRes
    ? topupBuyCardRes.data.map((item, idx) => ({
      ...item,
      id: idx.toString()
    })) : []), [topupBuyCardRes]);

  /* Functions */
  const convertTelco = (telco?: Telco): string => {
    switch (telco) {
      case 1:
        return 'Viettel';
      case 2:
        return 'Vinaphone';
      case 3:
        return 'Mobifone';
      case 4:
        return 'Viettel';
      default:
        return '';
    }
  };

  const detectBuyCardStatus = (status: number) => {
    switch (status) {
      case 1:
        return BuyCardStatusEnum.Never;
      case 2:
        return BuyCardStatusEnum.Failed;
      case 3:
        return BuyCardStatusEnum.Processing;
      default:
        return BuyCardStatusEnum.Success;
    }
  };

  const columns: ColumnsType<TopupBuyCardData> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: TopupBuyCardData) => (
        <Typography.Text>
          {data.buyCardData.id || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('topupBuyCards.phone'),
      key: 'phone',
      render: (_name: string, data: TopupBuyCardData) => (
        <Typography.Text>
          {formatPhoneVietnamese((data.buyCardData.phone || '').toString())}
        </Typography.Text>
      ),
    },
    {
      title: t('topupBuyCards.attemptDataUId'),
      key: 'attemptDataUId',
      sorter: {},
      sortDirections: ['ascend', 'descend'],
      className: 'fetchColumn',
      onHeaderCell: () => ({
        onClick: () => {
          setSortType({ field: 'topup_attempts.uid', type: sortType?.field !== 'topup_attempts.uid' ? 'asc' : detectSortType(sortType?.type) });
        },
      }),
      render: (_name: string, data: TopupBuyCardData) => (
        <Typography.Text>
          {data.attemptData.uid || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('topupBuyCards.buyCardUId'),
      key: 'buyCardUId',
      sorter: {},
      sortDirections: ['ascend', 'descend'],
      className: 'fetchColumn',
      onHeaderCell: () => ({
        onClick: () => {
          setSortType({ field: 'topup_buy_cards.uid', type: sortType?.field !== 'topup_buy_cards.uid' ? 'asc' : detectSortType(sortType?.type) });
        },
      }),
      render: (_name: string, data: TopupBuyCardData) => (
        <Typography.Text>
          {data.buyCardData.uid || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('topupBuyCards.telco'),
      key: 'telco',
      render: (_name: string, data: TopupBuyCardData) => (
        <Typography.Text>
          {convertTelco(data.buyCardData.telco)}
        </Typography.Text>
      ),
    },
    // {
    //   title: t('topupBuyCards.telcoCustomer'),
    //   key: 'telcoCustomer',
    //   render: (_name: string, data: TopupBuyCardData) => (
    //     <Typography.Text>
    //       {convertTelco(data.buyCardData?.telcoCustomer)}
    //     </Typography.Text>
    //   ),
    // },
    {
      title: t('topupBuyCards.luckyCode'),
      key: 'luckyCode',
      render: (_name: string, data: TopupBuyCardData) => (
        <Typography.Text>
          {data.luckyCodeData?.luckyCode}
        </Typography.Text>
      ),
    },
    {
      title: t('topupBuyCards.buyCardStatus'),
      key: 'status',
      align: 'center',
      render: (_name: string, data: TopupBuyCardData) => (
        <StatusBuyCardLabel status={detectBuyCardStatus(data.buyCardData.status)} />
      ),
    },
    {
      title: t('topupBuyCards.amount'),
      key: 'amount',
      render: (_name: string, data: TopupBuyCardData) => (
        <Typography.Text>
          {formatNumberWithSeparators(data.buyCardData.amount * 1000)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {},
      sortDirections: ['ascend', 'descend'],
      className: 'fetchColumn',
      onHeaderCell: () => ({
        onClick: () => {
          setSortType({ field: 'topup_buy_cards.created_at', type: sortType?.field !== 'topup_buy_cards.created_at' ? 'asc' : detectSortType(sortType?.type) });
        },
      }),
      render: (_name: string, data: TopupBuyCardData) => (
        <Typography.Text>
          {formatDateTime(data.buyCardData.createdAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('topupBuyCards.successAt'),
      dataIndex: 'successAt',
      key: 'successAt',
      sorter: {},
      sortDirections: ['ascend', 'descend'],
      className: 'fetchColumn',
      onHeaderCell: () => ({
        onClick: () => {
          setSortType({ field: 'topup_buy_cards.updated_at', type: sortType?.field !== 'topup_buy_cards.updated_at' ? 'asc' : detectSortType(sortType?.type) });
        },
      }),
      render: (_name: string, data: TopupBuyCardData) => (
        <Typography.Text>
          {formatDateTime(data.buyCardData.updatedAt || '')}
        </Typography.Text>
      ),
    },
    // --- Xem
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (_name: string, data: TopupBuyCardData) => {
        const { attemptData: { id }, prizeData: { successful } } = data;
        return (
          <Space>
            <Tooltip title={t('system.buyCardTopUp')}>
              <Button
                loading={buyCardLoading}
                disabled={!(getPermission(roleOther, roles.TOPUP_ATTEMPT_BUY_CARD)
                  && !successful)}
                icon={<ShoppingOutlined />}
                onClick={() => buyCardMutate(id)}
              />
            </Tooltip>
          </Space>
        );
      }
    },
  ];

  // Change page view
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  // Change page size
  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  // Add filter
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  /* Effects */
  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);
  });

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.topupBuyCards')}
        rightHeader={(
          <Space>
            <Button
              type="primary"
              loading={exportAccountLoading}
              disabled={!getPermission(roleOther, roles.TOPUP_BUY_CARDS_EXPORT_INCLUDE_ACCOUNT)
                || exportAccountLoading}
              onClick={() => exportAccountMutate()}
            >
              {t('topupBuyCards.exportIncludeAccount')}
            </Button>
            <Button
              type="primary"
              loading={exportLoading}
              disabled={!getPermission(roleOther, roles.TOPUP_BUY_CARDS_EXPORT)
                || exportLoading}
              onClick={() => exportMutate()}
            >
              {t('system.export')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading}
          handleSearch={setKeyword}
          tableProps={{
            initShowColumns: ['id', 'phone', 'attemptDataUId', 'buyCardUId', 'telco', 'luckyCode', 'status', 'amount', 'createdAt', 'updatedAt', 'action'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: topupBuyCardRes?.meta.total ?? 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
          noCheckbox
          leftCustomForm={(
            <Select
              style={{ width: '100%' }}
              placeholder="Lọc theo giai đoạn"
              allowClear
              // onClear={() => onChangeSelect({ id: data.topupData.id.value, type: undefined })}
              value={selectedPhase}
              onChange={(val) => setSelectedPhase(val)}
            >
              {
                customerLuckyCodePhases.map((item, index) => (
                  <Select.Option value={item.value} key={`option-${index.toString()}`}>
                    {t(item.label)}
                  </Select.Option>
                ))
              }
            </Select>
          )}
        />
      </div>
    </>
  );
};

export default TopupBuyCard;
