/* eslint-disable quote-props */
import { EyeOutlined } from '@ant-design/icons';
import {
  Button, Select, Space, Typography,
  message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter,
  mappingQueryParamsLocationFilter
} from 'common/components/PageTable/filter';
import useDidMount from 'common/hooks/useDidMount';
import { exportCustomerService, getCustomerLuckyCodeService } from 'common/services/customer';
import { CustomerLuckyCodeData } from 'common/services/customer/types';
import { ROUTE_PATHS, customerLuckyCodePhases } from 'common/utils/constant';
import { detectSortType, formatDateTime, formatPhoneVietnamese } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';

const CustomerLuckyCodes: React.FC<ActiveRoles> = ({
  roleCreate, roleDelete, roleUpdate, roleView, roleOther,
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const {
    defaultPageSize, advancedFilter
  } = useAppSelector((state) => state.system);

  /* State */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [province, setProvince] = useState<number | undefined>(mappingQueryParamsLocationFilter(params, 'provinceId'));
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const [sortType, setSortType] = useState<{ field: string, type: SortType | undefined }>();
  const [selectedPhase, setSelectedPhase] = useState<number | undefined>(undefined);

  /* Variables */
  const telcoData: { [key: number]: string } = {
    1: 'Viettel', 2: 'Vinaphone', 3: 'Mobifone', 4: 'Vietnamobile',
  };
  const sourceData: { [key: number]: string } = {
    1: 'Microsite', 2: 'ZaloOA', 3: 'SMS'
  };
  const prizeData: { [key: string]: string } = {
    'top-up': t('customerLuckyCode.topup'),
    '1st': t('customerLuckyCode.first'),
    'special': t('customerLuckyCode.special'),
  };

  const prizeTypeFirstData: { [key: string]: string } = {
    '1': t('customerLuckyCode.backupFirst'),
    '2': t('customerLuckyCode.notBackupFirst'),
  };

  const prizeTypeSpecialData: { [key: string]: string } = {
    '1': t('customerLuckyCode.backupSpecial'),
    '2': t('customerLuckyCode.notBackupSpecial'),
  };

  const returnParamsFilter = useMemo(
    () => ({
      ...mappingParamsFilter(selectedFilterList),
      ...province && { 'provinceId[is][0]': province },
    }),
    [selectedFilterList, province]
  );

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('customerLuckyCode', advancedFilter),
    [advancedFilter]
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['exportCustomerLuckyDrawMutate'],
    () => exportCustomerService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter,
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.uploadError'));
      }
    }
  );

  const textPrizeType = (data: CustomerLuckyCodeData) => {
    if (data.prize === '1st') {
      return data.backup ? prizeTypeFirstData[data.backup.toString()] : '';
    }
    if (data.prize === 'special') {
      return data.backup ? prizeTypeSpecialData[data.backup.toString()] : '';
    }
    return '';
  };

  const columns: ColumnsType<CustomerLuckyCodeData> = [
    {
      title: 'ID',
      key: 'id',
      width: 100,
      align: 'center',
      fixed: 'left',
      sorter: {},
      sortDirections: ['ascend', 'descend'],
      onHeaderCell: () => ({
        onClick: () => {
          setSortType({ field: 'customer_lucky_codes.id', type: sortType?.field !== 'customer_lucky_codes.id' ? 'asc' : detectSortType(sortType?.type) });
        },
      }),
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {data.id || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerLuckyCode.fullName'),
      key: 'fullName',
      sorter: {},
      sortDirections: ['ascend', 'descend'],
      onHeaderCell: () => ({
        onClick: () => {
          setSortType({ field: 'customer_lucky_codes.fullName', type: sortType?.field !== 'customer_lucky_codes.fullName' ? 'asc' : detectSortType(sortType?.type) });
        },
      }),
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {data.fullName || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerLuckyCode.phone'),
      key: 'phone',
      sorter: {},
      sortDirections: ['ascend', 'descend'],
      onHeaderCell: () => ({
        onClick: () => {
          setSortType({ field: 'customers.phone', type: sortType?.field !== 'customers.phone' ? 'asc' : detectSortType(sortType?.type) });
        },
      }),
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {formatPhoneVietnamese((data.phone || '').toString())}
        </Typography.Text>
      ),
    },
    {
      title: t('customerLuckyCode.zaloPhone'),
      key: 'zaloPhone',
      sortDirections: ['ascend', 'descend'],
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {formatPhoneVietnamese((data.zaloPhone || '').toString())}
        </Typography.Text>
      ),
    },
    {
      title: t('customerLuckyCode.telco'),
      key: 'telco',
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {telcoData[data.telco] || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerLuckyCode.telcoTopup'),
      key: 'telcoTopup',
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {data.telcoTopup ? telcoData[data.telcoTopup] : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerLuckyCode.luckyCode'),
      key: 'luckyCode',
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {data.luckyCode || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerLuckyCode.source'),
      key: 'source',
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {sourceData[data.source] || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('location.province'),
      key: 'province',
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {data.province?.name || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('location.district'),
      key: 'district',
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {data.district?.name || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerLuckyCode.store'),
      key: 'store',
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {data.store?.name || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerLuckyCode.note'),
      key: 'note',
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {data.note || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerLuckyCode.prize'),
      key: 'prize',
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {data.prize ? prizeData[data.prize] : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerLuckyCode.prizeType'),
      key: 'prizeType',
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {textPrizeType(data)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_name: string, data: CustomerLuckyCodeData) => (
        <Typography.Text>
          {formatDateTime(data.createdAt || '')}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: CustomerLuckyCodeData) => (
        <Space>
          <Button
            disabled={!roleView}
            icon={<EyeOutlined />}
            onClick={() => navigation(`${ROUTE_PATHS.CUSTOMER_LUCKY_CODE_DETAIL}?code=${_data.luckyCode}`)}
          />
        </Space>
      ),
    },
  ];

  /* Queries */
  const queryKey = ['getCustomerLuckyCode', keyword, currentPage, currentView, selectedFilterList, sortType, selectedPhase, province];
  const { data: customerData, isLoading } = useQuery(
    queryKey,
    () => getCustomerLuckyCodeService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter,
      sortBy: sortType?.field,
      sortType: sortType?.type,
      phase: selectedPhase,
    }),
    {
      enabled: !!currentPage,
      cacheTime: 0,
    }
  );

  /* Functions */

  // Change page view
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  // Change page size
  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  // Add filter
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  /* Effects */
  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...province?.toString() && { 'provinceId.is.string': province?.toString() || '' },
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList, province]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);
  });

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.customerLuckyCode')} // title
        rightHeader={(
          <Button
            type="primary"
            loading={exportLoading}
            disabled={!getPermission(roleOther, roles.CUSTOMER_LUCKY_CODE_EXPORT)
              || exportLoading}
            onClick={() => exportMutate()}
          >
            {t('system.export')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleDelete={() => { }}
          isLoading={isLoading}
          handleSearch={setKeyword}
          roles={{ roleCreate, roleDelete, roleUpdate }}
          tableProps={{
            initShowColumns: ['id', 'fullName', 'phone', 'zaloPhone', 'prize', 'prizeType', 'telco', 'telcoTopup', 'luckyCode', 'source', 'province', 'store', 'createdAt', 'action'],
            columns,
            pageData: customerData?.data || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: customerData?.meta.total,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields,
          }}
          hasFilterLocation
          filtersDataTable={{
            selectedFilterList,
            handleFilter,
            handleDeleteFilter,
            handleSelectProvince: (value) => setProvince(value),
            provinceValue: province,
          }}
          noCheckbox
          leftCustomForm={(
            <Select
              style={{ width: '100%' }}
              placeholder="Lọc theo giai đoạn"
              allowClear
              // onClear={() => onChangeSelect({ id: data.topupData.id.value, type: undefined })}
              value={selectedPhase}
              onChange={(val) => setSelectedPhase(val)}
            >
              {
                customerLuckyCodePhases.map((item, index) => (
                  <Select.Option value={item.value} key={`option-${index.toString()}`}>
                    {t(item.label)}
                  </Select.Option>
                ))
              }
            </Select>
          )}
        />
      </div>
    </>
  );
};

export default CustomerLuckyCodes;
