/* eslint-disable */
import { ArcElement, Chart, Plugin, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { forwardRef, useCallback, useMemo } from "react";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";

import htmlLegendPiePlugin, {
  htmlMiddleTitleDoughnut,
} from "common/utils/chart";
import { formatNumberWithSeparators } from "common/utils/functions";

export type ShapeType = "bar" | "line" | "pie" | "doughnut";
export interface ChartCustomProps {
  data: any;
  options?: any;
  height?: number | string;
  width?: number;
  legendCustomId?: string;
  type?: ShapeType;
  isMultipleType?: boolean;
  idLegendTooltip?: string;
  percentage?: boolean;
  hasDatalabels?: boolean;
}
// register Plugins
Chart.register(ArcElement);
Chart.register(...registerables);
Chart.register(ChartDataLabels);
// config datalabels: https://chartjs-plugin-datalabels.netlify.app/guide/options.html#scriptable-options

const ChartCustom: React.ForwardRefRenderFunction<any, ChartCustomProps> = (
  {
    data,
    options,
    height,
    width,
    type,
    legendCustomId,
    isMultipleType,
    idLegendTooltip,
    percentage,
    hasDatalabels,
  },
  ref
) => {
  const transposedData = data.labels?.map((_: any, idx: number) =>
    data.datasets.map((dataset: any) => dataset.data[idx])
  );

  const footer = (tooltipItems: any) => {
    if (tooltipItems.length === 0) return "";
    const dataPositionFocusInRow =
      transposedData[tooltipItems[0].dataIndex][tooltipItems[0].datasetIndex];

    if (type === "bar") {
      const dataRowFocus = transposedData[tooltipItems[0].dataIndex];
      const sumDataIndex = dataRowFocus.reduce(
        (acc: any, val: any) => Number(acc || 0) + Number(val || 0),
        0
      );
      return `Tỉ lệ: ${((dataPositionFocusInRow / sumDataIndex) * 100).toFixed(
        2
      )}%`;
    }
    const sumDataIndex = transposedData.reduce(
      (acc: any, val: any) => Number(acc || 0) + Number(val || 0),
      0
    );
    return `Tỉ lệ: ${((dataPositionFocusInRow / sumDataIndex) * 100).toFixed(
      2
    )}%`;
  };

  const defaultOptions = useMemo(() => ({
    ...options,
    aspectRatio: 1,
    plugins: {
      ...options?.plugins,
      legend: {
        ...options?.plugins?.legend,
        display: legendCustomId ? false : options?.plugins?.legend?.display,
      },
      tooltip: {
        ...options?.plugins?.tooltip,
        callbacks: {
          ...options?.plugins?.tooltip?.callbacks,
          ...(percentage && { footer })
        },
      },
      ...(hasDatalabels ? {
        datalabels: {
          formatter: (value: any, context: any) => {
            const { dataset } = context;
            const valueData = dataset.data[context.dataIndex];
            const sumDataIndex = dataset.data?.reduce((acc: any, val: any) => Number(acc)
              + Number(val), 0);

            return `${context.chart.data.labels[context.dataIndex]}: ${valueData}\n Tỉ lệ: ${(valueData / sumDataIndex * 100).toFixed(2)}%`;
          },
          color: 'white',
          font: {
            weight: 400,
            size: 12,
          },
          anchor: "center", //start, center, end
          rotation: function (ctx: any) {
            const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce((a: any, b: any) => a + b, 0);
            const sum = ctx.dataset.data.reduce((a: any, b: any) => a + b, 0);
            const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] / 2) / sum * 360);
            return rotation > 90 && rotation < 180 ? rotation - 180 : rotation;
          },
        },
      } : {
        datalabels: {
          display: false,
        }
      }),
    }
  }), [options, legendCustomId, percentage, footer, type, hasDatalabels]);

  const renderChartType = useCallback(() => {
    switch (type) {
      case "line":
        return (
          <Line
            ref={ref}
            data={data}
            options={defaultOptions}
            height={height}
            width={width}
            plugins={
              legendCustomId
                ? [htmlLegendPiePlugin(legendCustomId, isMultipleType)]
                : undefined
            }
          />
        );
      case "bar":
        return (
          <Bar
            ref={ref}
            data={data}
            options={defaultOptions}
            height={height}
            width={width}
            plugins={
              legendCustomId
                ? [htmlLegendPiePlugin(legendCustomId, isMultipleType)]
                : undefined
            }
          />
        );
      case "pie":
        return (
          <Pie
            ref={ref}
            plugins={
              legendCustomId
                ? [htmlLegendPiePlugin(legendCustomId, isMultipleType)]
                : undefined
            }
            data={data}
            options={defaultOptions}
            height={height}
            width={width}
          />
        );
      case "doughnut":
        return (
          <Doughnut
            ref={ref}
            data={data}
            options={defaultOptions}
            height={height}
            width={width}
            plugins={[
              legendCustomId
                ? htmlLegendPiePlugin(legendCustomId, isMultipleType)
                : { id: "" },
              htmlMiddleTitleDoughnut(percentage) as Plugin<"doughnut">,
            ]}
          />
        );
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    type,
    data,
    height,
    width,
    legendCustomId,
    isMultipleType,
    defaultOptions,
    idLegendTooltip,
  ]);

  return (
    <div className={`o-chart o-chart-${type}`}>
      <div
        className={`o-chart_panel ${!(data && data?.datasets?.length && data?.labels?.length) && "empty"
          }`}
      >
        {data && data?.datasets?.length && data?.labels?.length > 0
          ? renderChartType()
          : null}
      </div>
      {legendCustomId && (
        <div id={legendCustomId} className="o-chart_legendCustom" />
      )}
    </div>
  );
};

const ChartRef = forwardRef(ChartCustom);

ChartRef.defaultProps = {
  options: {},
  height: undefined,
  width: undefined,
  legendCustomId: "",
};

export default ChartRef;
