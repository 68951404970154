import { EyeOutlined, OrderedListOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Col,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
  message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PopupAttempts from './popupAttempts';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps,
  FilterValueProps,
  mappingFilterFields,
  mappingFilterToQuery,
  mappingParamsFilter,
  mappingQueryParamsFilter,
} from 'common/components/PageTable/filter';
import { StatusTopUpLabel } from 'common/components/StatusLabel';
import { deleteCustomerTopUpPrizeService } from 'common/services/customerPrizes';
import {
  bulkBuyCardTopUpRequest,
  bulkRetryTopUpRequest,
  exportTopupRequestIncludeAccountService,
  exportTopupRequestService,
  getTopupRequestList,
} from 'common/services/topupRequest';
import { TopUpRequestItemTypes } from 'common/services/topupRequest/types';
import {
  ROUTE_PATHS, customerLuckyCodePhases, phasePrizeDummy, sources,
  telcos
} from 'common/utils/constant';
import {
  detectError,
  formatDateTime,
  formatPhoneNumber,
} from 'common/utils/functions';
import { deletePrizeSchema } from 'common/utils/schemas';
import roles, { getPermission } from 'configs/roles';

export type DeleteFormTypes = {
  phase: number;
  apiKey: string;
};

type ModalTypes = 'export' | 'delete';

const TopUpRequestManagementContainer: React.FC<ActiveRoles> = ({
  roleIndex,
  roleCreate,
  roleUpdate,
  roleOther,
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Selectors */
  const { system: { defaultPageSize, advancedFilter }, auth: { roles: roleUser } } = useAppSelector(
    (state) => state
  );

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]
  >(mappingQueryParamsFilter(params));
  const [requestId, setRequestId] = useState(-1);
  const [isOpen, setIsOpen] = useState<ModalTypes | undefined>(undefined);
  const [selectedPhase, setSelectedPhase] = useState<number | undefined>(undefined);

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* Form */
  const deleteMethod = useForm<DeleteFormTypes>({
    mode: 'onChange',
    defaultValues: {
      phase: 1,
      apiKey: '',
    },
    resolver: yupResolver(deletePrizeSchema)
  });

  /* React-query */
  const { isLoading: listLoading, data: listData } = useQuery(
    ['topup-request-list', currentPage, currentView, selectedFilterList, selectedPhase],
    () => getTopupRequestList({
      page: currentPage,
      limit: currentView,
      phase: selectedPhase,
      ...returnParamsFilter,
    }),
    { keepPreviousData: true, enabled: !!currentPage }
  );

  const { mutate: bulkBuyCardMutate, isLoading: bulkBuyCardLoading } = useMutation(
    ['topup-request-bulk-buyCard'],
    ({ ids, params: buyCardParams }:
      { ids?: string[]; params?: any }) => bulkBuyCardTopUpRequest({
        ...(ids && { ids }),
        ...(buyCardParams && { params: buyCardParams }),
      }),
    {
      onSuccess() {
        message.success(t('message.buyCardSuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        } else if (Array.isArray(err) && err.length > 0) {
          message.error(
            t(`message.${err[0].message.toLowerCase().replaceAll(' ', '_')}`)
          );
        }
      },
    }
  );

  const { mutate: bulkRetryMutate, isLoading: bulkRetryLoading } = useMutation(
    ['topup-request--bulk-retry'],
    ({ ids, params: retryParams }: { ids?: string[]; params?: any }) => bulkRetryTopUpRequest({
      ...(ids && { ids }),
      ...(retryParams && { params: retryParams }),
    }),
    {
      onSuccess() {
        message.success(t('message.retrySuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        } else if (Array.isArray(err) && err.length > 0) {
          message.error(
            t(`message.${err[0].message.toLowerCase().replaceAll(' ', '_')}`)
          );
        }
      },
    }
  );

  const { mutate: exportTopupMutate, isLoading: exportLoading } = useMutation(
    ['exportTopupRequestMutate'],
    () => exportTopupRequestService({
      page: currentPage,
      limit: currentView,
      phase: selectedPhase,
      ...returnParamsFilter,
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(res.link, '_blank');
        }
      },
      onError: () => {
        message.error(t('message.uploadError'));
      },
    }
  );

  const {
    mutate: exportTopupIncludeAccountMutate,
    isLoading: exportIncludeAccountLoading,
  } = useMutation(
    ['exportTopupRequestIncludeAccountMuate'],
    () => exportTopupRequestIncludeAccountService({
      page: currentPage,
      limit: currentView,
      phase: selectedPhase,
      ...returnParamsFilter,
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(res.link, '_blank');
        }
      },
      onError: () => {
        message.error(t('message.uploadError'));
      },
    }
  );

  const { mutate: deleteCustomerTopUpPrizesMutate, isLoading: deleteLoading } = useMutation(
    ['deleteCustomerTopUpPrizesMutate'],
    deleteCustomerTopUpPrizeService,
    {
      onSuccess() {
        message.success(t('message.deleteSuccess'));
        setIsOpen(undefined);
        queryClient.invalidateQueries('getCustomerFirstPrize');
      },
      onError() {
        message.error(t('message.deleteError'));
      },
      onSettled() {
        deleteMethod.reset();
      }
    }
  );

  /* Datas */
  const columns: ColumnsType<TopUpRequestItemTypes> = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (_name: string, data: TopUpRequestItemTypes) => (
          <Typography.Text className="max-content">{data.id}</Typography.Text>
        ),
      },
      {
        title: t('topupRequest.telco'),
        dataIndex: 'telco',
        key: 'telco',
        width: 100,
        render: (_name: string, data: TopUpRequestItemTypes) => (
          <Typography.Text>
            {telcos[data.lastAttemptData[0]?.telco as keyof typeof telcos]}
          </Typography.Text>
        ),
      },
      {
        title: t('topupBuyCards.telcoCustomer'),
        dataIndex: 'telcoCustomer',
        key: 'telcoCustomer',
        width: 100,
        render: (_name: string, data: TopUpRequestItemTypes) => (
          <Typography.Text>
            {telcos[data.telco as keyof typeof telcos]}
          </Typography.Text>
        ),
      },
      {
        title: t('system.phone'),
        dataIndex: 'phone',
        width: 150,
        key: 'phone',
        render: (_name: string, data: TopUpRequestItemTypes) => (
          <Typography.Text>
            {formatPhoneNumber(data.phone, true)}
          </Typography.Text>
        ),
      },
      {
        title: t('customerPrize.zaloPhone'),
        dataIndex: 'zaloPhone',
        width: 150,
        key: 'zaloPhone',
        render: (_name: string, data: TopUpRequestItemTypes) => (
          <Typography.Text>
            {formatPhoneNumber(data.zaloPhone, true)}
          </Typography.Text>
        ),
      },
      {
        title: t('customerPrize.source'),
        dataIndex: 'source',
        width: 150,
        key: 'source',
        align: 'center',
        render: (_name: string, data: TopUpRequestItemTypes) => (
          <Typography.Text>
            {sources[data.source as keyof typeof sources]}
          </Typography.Text>
        ),
      },
      {
        title: t('customerLuckyCode.luckyCode'),
        dataIndex: 'luckyCode',
        width: 200,
        key: 'luckyCode',
        render: (_name: string, data: TopUpRequestItemTypes) => (
          <Typography.Text>{data.luckyCode}</Typography.Text>
        ),
      },
      {
        title: t('callCenter.topupStatus'),
        dataIndex: 'topupStatus',
        key: 'topupStatus',
        width: 300,
        // sorter: {
        //   compare: (a: TopUpRequestItemTypes, b: TopUpRequestItemTypes) => a.status - b.status,
        // },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopUpRequestItemTypes) => (
          <Space style={{ justifyContent: 'space-between', width: '100%' }}>
            {data.status && <StatusTopUpLabel status={data.status} />}
            <Tooltip title={t('system.seeAttemptStreams')}>
              <Button
                disabled={!roleIndex}
                icon={<OrderedListOutlined />}
                onClick={() => setRequestId(data.id)}
              />
            </Tooltip>
          </Space>
        ),
      },
      // {
      //   title: t('callCenter.postPaid'),
      //   dataIndex: 'postPaid',
      //   key: 'postPaid',
      //   width: 100,
      //   render: (_name: string, data: TopUpRequestItemTypes) => (
      //     <Typography.Text>{data.postPaid ? 'Thuê bao trả sau' :
      // 'Thuê bao trả trước'}</Typography.Text>
      //   ),
      // },
      // {
      //   title: t('system.attemptStatusCode'),
      //   dataIndex: 'attemptStatusCode',
      //   align: 'center',
      //   key: 'attemptStatusCode',
      //   render: (_name: string, { lastAttemptData }: TopUpRequestItemTypes) => (
      //     <Typography.Text>
      //       {lastAttemptData?.response?.data?.code ||
      // lastAttemptData?.response?.errors?.code || '-'}
      //     </Typography.Text>
      //   ),
      // },
      // --- Thoi gian tao
      {
        title: t('topupRequest.createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        // sorter: {
        //   compare: (a: TopUpRequestItemTypes, b: TopUpRequestItemTypes) => {
        //     const aDate = new Date(a.createdAt || 0);
        //     const bDate = new Date(b.createdAt || 0);
        //     return Number(aDate) - Number(bDate);
        //   },
        // },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopUpRequestItemTypes) => (
          <Typography.Text>{formatDateTime(data.createdAt)}</Typography.Text>
        ),
      },
      // // --- Thành công lúc
      {
        title: t('topupRequest.successAt'),
        dataIndex: 'successAt',
        key: 'successAt',
        // sorter: {
        //   compare: (a: TopUpRequestItemTypes, b: TopUpRequestItemTypes) => {
        //     const aDate = new Date(a.successAt || 0);
        //     const bDate = new Date(b.successAt || 0);
        //     return Number(aDate) - Number(bDate);
        //   },
        // },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopUpRequestItemTypes) => (
          <Typography.Text>
            {formatDateTime(data.successAt)}
          </Typography.Text>
        ),
      },
      // --- Xem
      {
        title: t('system.action'),
        key: 'action',
        align: 'center',
        width: 100,
        render: (_name: string, _data: TopUpRequestItemTypes) => {
          const { id } = _data;
          return (
            <Space>
              <Tooltip title={t('system.seeAttempt')}>
                <Button
                  disabled={!roleIndex}
                  icon={<EyeOutlined />}
                  onClick={() => {
                    navigate(
                      `${ROUTE_PATHS.TOPUP_ATTEMPT_MANAGEMENT}?id=${id}`
                    );
                  }}
                />
              </Tooltip>
            </Space>
          );
        },
      },
    ],
    [t, navigate, roleIndex]
  );

  const tableData: TopUpRequestItemTypes[] = useMemo(
    () => listData?.data?.map((item) => ({
      ...item.customerPrize,
      lastAttemptData: item.lastAttempts,
    })) || [],
    [listData]
  );

  const attempsDataPopup = useMemo(
    () => listData?.data.find((item) => item.customerPrize?.id === requestId)
      ?.lastAttempts || [],
    [listData, requestId]
  );

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if (
      (typeFilter === 'isNull' || typeFilter === 'isNotNull')
      && selectedFilterList.find((item) => item.key === data.key)
    ) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
    handleSetCurrentPage(1);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(
      tempList.filter((item) => !(item.key === key && item.index === index))
    );
    handleSetCurrentPage(1);
  };

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('topupRequest', advancedFilter),
    [advancedFilter]
  );

  const extraOptions = useMemo(() => {
    const initOptions = [
      {
        isActive: getPermission(roleOther, roles.TOPUP_ATTEMPT_BULK_BUY_CARD),
        data: {
          label: t('system.buyCardTopUp'),
          value: 'buyCardAll',
        },
      },
      {
        isActive: getPermission(roleOther, roles.TOPUP_ATTEMPT_BULK_RETRY),
        data: {
          label: t('system.retryTopUp'),
          value: 'retryAll',
        },
      },
    ];
    return initOptions.filter((o) => o.isActive).map((o) => ({ ...o.data }));
  }, [roleOther, t]);

  const onSubmitDelete = async () => {
    const isValid = await deleteMethod.trigger();
    if (!isValid) return;
    const data = deleteMethod.getValues();
    deleteCustomerTopUpPrizesMutate(data);
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...(pageParam && { page: pageParam }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.topupRequest')}
        rightHeader={(
          <Space>
            {roleUser.includes('*') && (
              <Button
                type="default"
                loading={deleteLoading}
                disabled={deleteLoading}
                onClick={() => setIsOpen('delete')}
                style={{ backgroundColor: '#ee5e52', color: 'white' }}
              >
                {t('customerPrize.deletePhasePrizes')}
              </Button>
            )}
            <Button
              type="primary"
              loading={exportIncludeAccountLoading}
              disabled={
                !getPermission(
                  roleOther,
                  roles.TOPUP_ATTEMPT_EXPORT_INCLUDE_ACCOUNT
                ) || exportIncludeAccountLoading
              }
              onClick={() => exportTopupIncludeAccountMutate()}
            >
              {t('topupRequest.exportIncludeAccount')}
            </Button>
            <Button
              type="primary"
              loading={exportLoading}
              disabled={
                !getPermission(roleOther, roles.TOPUP_ATTEMPT_EXPORT)
                || exportLoading
              }
              onClick={() => exportTopupMutate()}
            >
              {t('system.export')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading || bulkBuyCardLoading || bulkRetryLoading}
          // handleSearch={() => undefined}
          noCheckbox={false}
          roles={{
            roleCreate,
            roleUpdate,
            roleDelete: true,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
          tableProps={{
            initShowColumns: [
              'id',
              'telco',
              'telcoCustomer',
              'phone',
              'zaloPhone',
              'source',
              'topupStatus',
              'createdAt',
              'successAt',
              'action',
            ],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields,
          }}
          buckActions={extraOptions}
          handleChangeBulkAction={(action, selected) => {
            switch (action) {
              case 'retryAll':
                bulkRetryMutate({
                  ids: selected.map((item) => item.id.toString()),
                });
                break;
              case 'buyCardAll':
                bulkBuyCardMutate({
                  ids: selected.map((item) => item.id.toString()),
                });
                break;
              // case 'retryAllPages':
              //   bulkRetryMutate({ params: { ...returnParamsFilter } });
              //   break;
              // case 'buyCardAllPages':
              //   bulkBuyCardMutate({ params: { ...returnParamsFilter } });
              //   break;
              default:
                break;
            }
          }}
          leftCustomForm={(
            <Select
              style={{ width: '100%' }}
              placeholder="Lọc theo giai đoạn"
              allowClear
              // onClear={() => onChangeSelect({ id: data.topupData.id.value, type: undefined })}
              value={selectedPhase}
              onChange={(val) => setSelectedPhase(val)}
            >
              {
                customerLuckyCodePhases.map((item, index) => (
                  <Select.Option value={item.value} key={`option-${index.toString()}`}>
                    {t(item.label)}
                  </Select.Option>
                ))
              }
            </Select>
          )}
        />
        <PopupAttempts
          isOpen={requestId !== -1}
          handleClose={() => setRequestId(-1)}
          info={attempsDataPopup}
        />
      </div>
      <Modal
        className="modal-detail"
        title={(
          <Typography.Title level={3}>
            {t('customerPrize.deletePhasePrizes')}
          </Typography.Title>
        )}
        open={isOpen === 'delete'}
        centered
        confirmLoading={deleteLoading}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={onSubmitDelete}
        onCancel={() => setIsOpen(undefined)}
      >
        <FormProvider {...deleteMethod}>
          <Row gutter={16}>
            <Col span={24}>
              <Typography.Text strong>
                {t('customerPrize.phase')}
              </Typography.Text>
              {' '}
              <Typography.Text strong type="danger">
                *
              </Typography.Text>
              <Controller
                name="phase"
                render={({ field: { value, onChange } }) => (
                  <Select
                    size="large"
                    className="u-mt-8"
                    style={{ width: '100%' }}
                    placeholder="---"
                    value={value}
                    onChange={onChange}
                  >
                    {phasePrizeDummy.map((val, idx) => (
                      <Select.Option
                        value={val.value}
                        key={`option-${idx.toString()}`}
                      >
                        {t(val.label)}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
            </Col>
            <Col span={24} className="u-mt-12">
              <Typography.Text strong>
                API Key
              </Typography.Text>
              {' '}
              <Typography.Text strong type="danger">
                *
              </Typography.Text>
              <Controller
                name="apiKey"
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Input
                    size="large"
                    className="u-mt-8"
                    placeholder="Nhập api Key"
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                  />
                )}
              />
            </Col>
          </Row>
        </FormProvider>
      </Modal>
    </>
  );
};

export default TopUpRequestManagementContainer;
