/* eslint-disable quote-props */
import { EyeOutlined, SendOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Col,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
  message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useDidMount from 'common/hooks/useDidMount';
import { deleteCustomerPrizesService, exportCustomerFirstPrizesService, getCustomerFirstPrizeService } from 'common/services/customer';
import { sendSMSFirstCustomerPrizeByIdService, sendSMSFirstCustomersPrizeService } from 'common/services/customerPrizes';
import {
  ROUTE_PATHS,
  colorStatusSMS,
  customerLuckyCodeFirstPrizeStage,
  firstPhaseTypes,
  phasePrizeDummy,
  textStatusSMS
} from 'common/utils/constant';
import { formatDateTime, formatPhoneVietnamese } from 'common/utils/functions';
import { deletePrizeSchema } from 'common/utils/schemas';
import roles, { getPermission } from 'configs/roles';

export type FormTypes = {
  phase: number;
  apiKey: string;
};

export interface CustomerFirstPrizeData {
  id: number
  fullName: string
  phone: string
  luckyCode: string
  province: string
  zaloPhone: string
  district: string
  createdAt: string
  luckyCodeCreatedAt: string
  isBackup: boolean
  phase: number
  smsStatus?: 1 | 2 | 3,
  stage?: number
}

const CustomerFirstPrize: React.FC<ActiveRoles> = ({ roleOther }) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigation = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const { system: { defaultPageSize, advancedFilter }, auth: { roles: roleUser } } = useAppSelector(
    (state) => state
  );

  /* State */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const [selectedPhase, setSelectedPhase] = useState<number | undefined>(undefined);
  const [selectedStage, setSelectedStage] = useState<number | undefined>(undefined);

  /* Variables */
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('customerPrize', advancedFilter),
    [advancedFilter]
  );

  /* Form */
  const method = useForm<FormTypes>({
    mode: 'onChange',
    defaultValues: {
      phase: 1,
      apiKey: '',
    },
    resolver: yupResolver(deletePrizeSchema)
  });

  const stageByPhase = useMemo(() => {
    if (!selectedPhase && !selectedStage) return undefined;
    if (!selectedPhase && selectedStage) return selectedStage;
    if (Number(selectedPhase) === 1 && Number(selectedStage) === 1) {
      return 1;
    }
    if (Number(selectedPhase) === 1 && Number(selectedStage) === 2) {
      return 2;
    }
    if (Number(selectedPhase) === 1 && Number(selectedStage) === 3) {
      return 3;
    }
    if (Number(selectedPhase) === 1 && Number(selectedStage) === 4) {
      return 4;
    }
    if (Number(selectedPhase) === 2 && Number(selectedStage) === 1) {
      return 5;
    }
    if (Number(selectedPhase) === 2 && Number(selectedStage) === 2) {
      return 6;
    }
    if (Number(selectedPhase) === 3 && Number(selectedStage) === 1) {
      return 7;
    }
    if (Number(selectedPhase) === 3 && Number(selectedStage) === 2) {
      return 8;
    }
    return undefined;
  }, [selectedStage, selectedPhase]);

  /* Queries */
  const queryKey = ['getCustomerFirstPrize', keyword, currentPage, currentView, selectedFilterList, selectedPhase, stageByPhase];
  const { data: customerData, isLoading } = useQuery(
    queryKey,
    () => getCustomerFirstPrizeService({
      keyword,
      page: currentPage,
      limit: currentView,
      phase: selectedPhase,
      ...stageByPhase && { stage: stageByPhase },
      ...returnParamsFilter,
    }),
    {
      enabled: !!currentPage,
    }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['exportCustomerFirstPrizesMutate'],
    () => exportCustomerFirstPrizesService({
      keyword,
      page: currentPage,
      limit: currentView,
      phase: selectedPhase,
      ...stageByPhase && { stage: stageByPhase },
      ...returnParamsFilter,
    }),
    {
      onSuccess(res) {
        if (res.link) {
          window.open(
            res.link,
            '_blank',
          );
        }
      },
      onError: () => {
        message.error(t('message.uploadError'));
      }
    }
  );

  const { mutate: sendSMSMutate, isLoading: sendSMSLoading } = useMutation(
    ['sendSMSFirstCustomerPrizeByIdMutate'],
    (id: string) => sendSMSFirstCustomerPrizeByIdService(id),
    {
      onSuccess() {
        queryClient.invalidateQueries(queryKey);
        message.success(t('message.sendSuccess'));
      },
      onError: () => {
        message.error(t('message.sendError'));
      }
    }
  );

  const { mutate: sendSMSMultipleMutate, isLoading: sendSMSMultipleLoading } = useMutation(
    ['sendSMSFirstCustomersPrizeMutate'],
    (ids: string[]) => sendSMSFirstCustomersPrizeService(ids),
    {
      onSuccess() {
        queryClient.invalidateQueries(queryKey);
        message.success(t('message.sendSuccess'));
      },
      onError: () => {
        message.error(t('message.sendError'));
      }
    }
  );

  const { mutate: deleteCustomerPrizesMutate, isLoading: deleteLoading } = useMutation(
    ['deleteCustomerPrizesMutate'],
    deleteCustomerPrizesService,
    {
      onSuccess() {
        method.reset();
        message.success(t('message.deleteSuccess'));
        setIsOpen(false);
        queryClient.invalidateQueries('getCustomerFirstPrize');
      },
      onError() {
        message.error(t('message.deleteError'));
      }
    }
  );

  const tableData: CustomerFirstPrizeData[] = useMemo(() => (
    customerData?.data.map((val) => ({
      id: val.customerPrize?.id,
      fullName: val.customer?.fullName || '',
      phone: val.customer?.phone || '',
      zaloPhone: val.customer?.zaloPhone || '',
      luckyCode: val.customerPrize?.luckyCode || '',
      province: val.customerPrize?.province?.name || '',
      district: val.customerPrize?.district?.name || '',
      createdAt: val.customerPrize?.createdAt || '',
      luckyCodeCreatedAt: val.customerPrize?.luckyCodeCreatedAt || '',
      isBackup: val.customerPrize?.isBackup || false,
      phase: val.customerPrize?.phase || -1,
      smsStatus: val.customerPrize?.smsStatus || undefined,
      stage: val.customerPrize?.stage || undefined,
    })) || []), [customerData]);

  const disableIds = useMemo(() => tableData.filter((val) => val?.smsStatus === 2)
    .map((val) => val.id), [tableData]);

  const textStage = (phase: number, stage?: number) => {
    if (stage) {
      if (phase === 1 && stage === 1) {
        return t('system.stage1');
      }
      if (phase === 1 && stage === 2) {
        return t('system.stage2');
      }
      if (phase === 2 && stage === 1) {
        return t('system.stage1');
      }
      if (phase === 2 && stage === 2) {
        return t('system.stage2');
      }
      if (phase === 3 && stage === 1) {
        return t('system.stage1');
      }
      if (phase === 3 && stage === 2) {
        return t('system.stage2');
      }
    }
    return '';
  };

  const columns: ColumnsType<CustomerFirstPrizeData> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Typography.Text>
          {data.id || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.fullName'),
      key: 'fullName',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Typography.Text>
          {data.fullName || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.phone'),
      key: 'phone',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Typography.Text>
          {formatPhoneVietnamese((data.phone || '').toString())}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.zaloPhone'),
      key: 'zaloPhone',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Typography.Text>
          {data.zaloPhone || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.luckyCode'),
      key: 'luckyCode',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Typography.Text>
          {data.luckyCode || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.luckyCodeCreatedAt'),
      dataIndex: 'luckyCodeCreatedAt',
      key: 'luckyCodeCreatedAt',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Typography.Text>
          {formatDateTime(data.luckyCodeCreatedAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.typePrize'),
      key: 'typePrize',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Typography.Text>
          {data.isBackup ? t('customerPrize.backup') : t('customerPrize.notBackup')}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.phase'),
      key: 'phase',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Typography.Text>
          {t(phasePrizeDummy.find((val) => val.value === data.phase)?.label || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.stage'),
      key: 'stage',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Typography.Text>
          {textStage(data.phase, data.stage)}
        </Typography.Text>
      ),
    },
    {
      title: t('location.province'),
      key: 'province',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Typography.Text>
          {data.province || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('location.district'),
      key: 'district',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Typography.Text>
          {data.district || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('customerPrize.smsStatus'),
      key: 'smsStatus',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Typography.Text type={colorStatusSMS(data.smsStatus)}>
          {t(textStatusSMS(data.smsStatus))}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Typography.Text>
          {formatDateTime(data.createdAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: CustomerFirstPrizeData) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => navigation(`${ROUTE_PATHS.CUSTOMER_FIRST_PRIZE_DETAIL}?id=${data.id}`)}
          />
          <Tooltip title={t('customerPrize.sendSMS')}>
            <Button
              loading={sendSMSLoading}
              disabled={!getPermission(roleOther, roles.CUSTOMER_PRIZE_SEND_SMS_FIRST)
                || data?.smsStatus === 2}
              icon={<SendOutlined />}
              onClick={() => {
                Modal.confirm({
                  autoFocusButton: 'cancel',
                  okText: t('system.ok'),
                  cancelText: t('system.cancel'),
                  cancelButtonProps: {
                    type: 'default',
                  },
                  okButtonProps: {
                    type: 'primary',
                  },
                  title: t('customerPrize.sendSmsConfirm'),
                  onOk: () => {
                    sendSMSMutate(data.id.toString());
                  },
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  /* Functions */
  const onSubmit = async () => {
    const isValid = await method.trigger();
    if (!isValid) return;
    const data = method.getValues();
    deleteCustomerPrizesMutate({
      phase: data.phase,
      prize: '1st',
      apiKey: data.apiKey
    });
  };

  // Change page view
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  // Change page size
  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  // Add filter
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  /* Effects */
  useEffect(() => {
    setCurrentPage(1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);
  });

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.customerFirstPrize')}
        rightHeader={(
          <Space>
            {roleUser.includes('*') && (
              <Button
                type="default"
                loading={deleteLoading}
                disabled={deleteLoading}
                onClick={() => setIsOpen(true)}
                style={{ backgroundColor: '#ee5e52', color: 'white' }}
              >
                {t('customerPrize.deletePrizes')}
              </Button>
            )}
            <Button
              type="primary"
              loading={exportLoading}
              disabled={!getPermission(roleOther, roles.CUSTOMER_PRIZE_EXPORT_FIRST_PRIZE)
                || exportLoading}
              onClick={() => exportMutate()}
            >
              {t('system.export')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading || sendSMSMultipleLoading}
          handleSearch={setKeyword}
          roles={{
            roleDelete: getPermission(roleOther, roles.CUSTOMER_PRIZE_SEND_SMS_FIRST),
            roleCreate: false,
            roleUpdate: false,
          }}
          tableProps={{
            initShowColumns: ['id', 'fullName', 'phone', 'zaloPhone', 'luckyCode', 'luckyCodeCreatedAt', 'typePrize', 'province', 'district', 'smsStatus', 'createdAt', 'action'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: customerData?.meta.total ?? 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
          leftCustomForm={(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                style={{ width: '100%' }}
                placeholder="Lọc theo giai đoạn"
                allowClear
                // onClear={() => onChangeSelect({ id: data.topupData.id.value, type: undefined })}
                value={selectedPhase}
                onChange={(val) => {
                  setSelectedPhase(val);
                }}
              >
                {
                  phasePrizeDummy.map((item, index) => (
                    <Select.Option value={item.value} key={`option-${index.toString()}`}>
                      {t(item.label)}
                    </Select.Option>
                  ))
                }
              </Select>
              <div style={{ marginLeft: '8px' }}>
                <Select
                  style={{ width: '100%' }}
                  placeholder="Lọc theo đợt"
                  allowClear
                  value={selectedStage}
                  onChange={(val) => {
                    setSelectedStage(val);
                  }}
                >
                  {
                    customerLuckyCodeFirstPrizeStage.map((item, index) => (
                      <Select.Option value={item.value} key={`option-${index.toString()}`}>
                        {t(item.label)}
                      </Select.Option>
                    ))
                  }
                </Select>
              </div>
            </div>
          )}
          buckActions={[
            {
              label: t('customerPrize.sendSMS'),
              value: 'sendSMS',
            }
          ]}
          handleChangeBulkAction={(action, selectedRow) => {
            if (action === 'sendSMS' && selectedRow.length > 0) {
              const ids = selectedRow.map((val) => val.id.toString());
              sendSMSMultipleMutate(ids);
            }
          }}
          disableIds={disableIds}
        />
      </div>
      <Modal
        className="modal-detail"
        title={<Typography.Title level={3}>{t('customerPrize.deletePrizes')}</Typography.Title>}
        open={isOpen}
        centered
        confirmLoading={isLoading}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={onSubmit}
        onCancel={() => setIsOpen(false)}
      >
        <FormProvider {...method}>
          <Row gutter={16}>
            <Col span={24}>
              <Typography.Text strong>
                {t('customerPrize.stage')}
              </Typography.Text>
              {' '}
              <Typography.Text strong type="danger">
                *
              </Typography.Text>
              <Controller
                name="phase"
                render={({ field: { value, onChange } }) => (
                  <Select
                    size="large"
                    className="u-mt-8"
                    style={{ width: '100%' }}
                    placeholder="---"
                    value={value}
                    onChange={onChange}
                  >
                    {
                      firstPhaseTypes.map((val, idx) => (
                        <Select.Option value={val.value} key={`option-${idx.toString()}`}>
                          {t(val.label)}
                        </Select.Option>
                      ))
                    }
                  </Select>
                )}
              />
            </Col>
            <Col span={24} className="u-mt-8">
              <Typography.Text strong>
                API Key
              </Typography.Text>
              {' '}
              <Typography.Text strong type="danger">
                *
              </Typography.Text>
              <Controller
                name="apiKey"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    className="u-mt-8"
                    error={error?.message}
                    {...field}
                  />
                )}
              />
            </Col>
          </Row>
        </FormProvider>
      </Modal>
    </>
  );
};

export default CustomerFirstPrize;
